.container {
  height: 48px;
  border-radius: 8px;
  padding: 4px 16px;

  border: 1px solid transparent;
  /* box-shadow: inset 0px 2px 2px -1px rgba(74, 74, 104, 0.256); */

  display: flex;
  align-items: center;
  overflow: hidden;
}

.inputBox {
  flex: 1;
  display: flex;
  height: 100%;
}

.input {
  flex: 1;
  outline: none;
  border: none;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  color: #0e0e2c;
}

.input::placeholder {
  font-size: 16px;
  font-weight: 500;
  color: #8c8ca1;
}
