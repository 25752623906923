/*DESCRIPTION BOX*/
.descriptionCard {
  border: var(--card-border);
  border-radius: 8px;
  max-width: 500px;
  padding: 8px;
}

.descriptionBanner {
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: var(--text-body);
  border-radius: 6px;
}

.descriptionCell {
  padding: 10px;
  padding-right: 80px;
}

.descriptionBody {
  padding: 8px 16px;
}
