:root {
  --fuschia: #2a333e;
  --iris: #4b4ded;
  --onyx: #0e0e2c;
  --peach: #e9b6cf;
  --evergreen: #46d5b2;
  --light: #fafcfe;
  --text-body: #5c5c77;
  --text-subtitle: #9797aa;
  --card-border-clr: rgba(151, 151, 170, 0.22);
}

.bg-fuschia {
  background-color: var(--fuschia);
}

.bg-iris {
  background-color: var(--iris);
}

.bg-onyx {
  background-color: var(--onyx);
}

.bg-peach {
  background-color: var(--peach);
}

.bg-evergreen {
  background-color: var(--evergreen);
}

.bg-light {
  background-color: var(--light);
}

.bg-text-body {
  background-color: var(--text-body);
}

.bg-text-subtitle {
  background-color: var(--text-subtitle);
}

.fcolor-fuschia {
  color: var(--fuschia);
}

.fcolor-iris {
  color: var(--iris);
}

.fcolor-onyx {
  color: var(--onyx);
}

.fcolor-peach {
  color: var(--peach);
}

.fcolor-evergreen {
  color: var(--evergreen);
}

.fcolor-light {
  color: var(--light);
}

.fcolor-text-body {
  color: var(--text-body);
}

.fcolor-text-subtitle {
  color: var(--text-subtitle);
}

.fcolor-red {
  color: red;
}