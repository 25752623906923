.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.crow {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
