.container {
	max-width: 600px;
	width: 100%;
	height: fit-content;
	border-radius: 10px;
	padding: 32px;
	box-shadow: 0px 4px 35px rgba(0, 0, 0, 0.238);
}

@media (max-width: 500px) {
	.container > div {
		padding: 0;
	}
}
@media (min-width: 500px) {
	.container {
		padding: 32px 48px;
		border-radius: 40px;
	}
}
