.ml-1 {
  margin-left: 8px;
}

.mr-1 {
  margin-right: 8px;
}

.mb-1 {
  margin-bottom: 8px;
}

.mt-1 {
  margin-top: 8px;
}

.m-1 {
  margin: 8px;
}

.round8-1 {
  border-radius: 8px;
}

.round6-1 {
  border-radius: 6px;
}

.pl-1 {
  padding-left: 8px;
}

.pr-1 {
  padding-right: 8px;
}

.pb-1 {
  padding-bottom: 8px;
}

.pt-1 {
  padding-top: 8px;
}

.p-1 {
  padding: 8px;
}

.ml-2 {
  margin-left: 16px;
}

.mr-2 {
  margin-right: 16px;
}

.mb-2 {
  margin-bottom: 16px;
}

.mt-2 {
  margin-top: 16px;
}

.m-2 {
  margin: 16px;
}

.round8-2 {
  border-radius: 16px;
}

.round6-2 {
  border-radius: 12px;
}

.pl-2 {
  padding-left: 16px;
}

.pr-2 {
  padding-right: 16px;
}

.pb-2 {
  padding-bottom: 16px;
}

.pt-2 {
  padding-top: 16px;
}

.p-2 {
  padding: 16px;
}

.ml-3 {
  margin-left: 24px;
}

.mr-3 {
  margin-right: 24px;
}

.mb-3 {
  margin-bottom: 24px;
}

.mt-3 {
  margin-top: 24px;
}

.m-3 {
  margin: 24px;
}

.round8-3 {
  border-radius: 24px;
}

.round6-3 {
  border-radius: 18px;
}

.pl-3 {
  padding-left: 24px;
}

.pr-3 {
  padding-right: 24px;
}

.pb-3 {
  padding-bottom: 24px;
}

.pt-3 {
  padding-top: 24px;
}

.p-3 {
  padding: 24px;
}

.ml-4 {
  margin-left: 32px;
}

.mr-4 {
  margin-right: 32px;
}

.mb-4 {
  margin-bottom: 32px;
}

.mt-4 {
  margin-top: 32px;
}

.m-4 {
  margin: 32px;
}

.round8-4 {
  border-radius: 32px;
}

.round6-4 {
  border-radius: 24px;
}

.pl-4 {
  padding-left: 32px;
}

.pr-4 {
  padding-right: 32px;
}

.pb-4 {
  padding-bottom: 32px;
}

.pt-4 {
  padding-top: 32px;
}

.p-4 {
  padding: 32px;
}

.ml-5 {
  margin-left: 40px;
}

.mr-5 {
  margin-right: 40px;
}

.mb-5 {
  margin-bottom: 40px;
}

.mt-5 {
  margin-top: 40px;
}

.m-5 {
  margin: 40px;
}

.round8-5 {
  border-radius: 40px;
}

.round6-5 {
  border-radius: 30px;
}

.pl-5 {
  padding-left: 40px;
}

.pr-5 {
  padding-right: 40px;
}

.pb-5 {
  padding-bottom: 40px;
}

.pt-5 {
  padding-top: 40px;
}

.p-5 {
  padding: 40px;
}

.ml-6 {
  margin-left: 48px;
}

.mr-6 {
  margin-right: 48px;
}

.mb-6 {
  margin-bottom: 48px;
}

.mt-6 {
  margin-top: 48px;
}

.m-6 {
  margin: 48px;
}

.round8-6 {
  border-radius: 48px;
}

.round6-6 {
  border-radius: 36px;
}

.pl-6 {
  padding-left: 48px;
}

.pr-6 {
  padding-right: 48px;
}

.pb-6 {
  padding-bottom: 48px;
}

.pt-6 {
  padding-top: 48px;
}

.p-6 {
  padding: 48px;
}

.ml-7 {
  margin-left: 56px;
}

.mr-7 {
  margin-right: 56px;
}

.mb-7 {
  margin-bottom: 56px;
}

.mt-7 {
  margin-top: 56px;
}

.m-7 {
  margin: 56px;
}

.round8-7 {
  border-radius: 56px;
}

.round6-7 {
  border-radius: 42px;
}

.pl-7 {
  padding-left: 56px;
}

.pr-7 {
  padding-right: 56px;
}

.pb-7 {
  padding-bottom: 56px;
}

.pt-7 {
  padding-top: 56px;
}

.p-7 {
  padding: 56px;
}

.ml-8 {
  margin-left: 64px;
}

.mr-8 {
  margin-right: 64px;
}

.mb-8 {
  margin-bottom: 64px;
}

.mt-8 {
  margin-top: 64px;
}

.m-8 {
  margin: 64px;
}

.round8-8 {
  border-radius: 64px;
}

.round6-8 {
  border-radius: 48px;
}

.pl-8 {
  padding-left: 64px;
}

.pr-8 {
  padding-right: 64px;
}

.pb-8 {
  padding-bottom: 64px;
}

.pt-8 {
  padding-top: 64px;
}

.p-8 {
  padding: 64px;
}

.ml-9 {
  margin-left: 72px;
}

.mr-9 {
  margin-right: 72px;
}

.mb-9 {
  margin-bottom: 72px;
}

.mt-9 {
  margin-top: 72px;
}

.m-9 {
  margin: 72px;
}

.round8-9 {
  border-radius: 72px;
}

.round6-9 {
  border-radius: 54px;
}

.pl-9 {
  padding-left: 72px;
}

.pr-9 {
  padding-right: 72px;
}

.pb-9 {
  padding-bottom: 72px;
}

.pt-9 {
  padding-top: 72px;
}

.p-9 {
  padding: 72px;
}

.ml-10 {
  margin-left: 80px;
}

.mr-10 {
  margin-right: 80px;
}

.mb-10 {
  margin-bottom: 80px;
}

.mt-10 {
  margin-top: 80px;
}

.m-10 {
  margin: 80px;
}

.round8-10 {
  border-radius: 80px;
}

.round6-10 {
  border-radius: 60px;
}

.pl-10 {
  padding-left: 80px;
}

.pr-10 {
  padding-right: 80px;
}

.pb-10 {
  padding-bottom: 80px;
}

.pt-10 {
  padding-top: 80px;
}

.p-10 {
  padding: 80px;
}

.ml-11 {
  margin-left: 88px;
}

.mr-11 {
  margin-right: 88px;
}

.mb-11 {
  margin-bottom: 88px;
}

.mt-11 {
  margin-top: 88px;
}

.m-11 {
  margin: 88px;
}

.round8-11 {
  border-radius: 88px;
}

.round6-11 {
  border-radius: 66px;
}

.pl-11 {
  padding-left: 88px;
}

.pr-11 {
  padding-right: 88px;
}

.pb-11 {
  padding-bottom: 88px;
}

.pt-11 {
  padding-top: 88px;
}

.p-11 {
  padding: 88px;
}

.ml-12 {
  margin-left: 96px;
}

.mr-12 {
  margin-right: 96px;
}

.mb-12 {
  margin-bottom: 96px;
}

.mt-12 {
  margin-top: 96px;
}

.m-12 {
  margin: 96px;
}

.round8-12 {
  border-radius: 96px;
}

.round6-12 {
  border-radius: 72px;
}

.pl-12 {
  padding-left: 96px;
}

.pr-12 {
  padding-right: 96px;
}

.pb-12 {
  padding-bottom: 96px;
}

.pt-12 {
  padding-top: 96px;
}

.p-12 {
  padding: 96px;
}
