/** sizes */
.header-2 {
  font-size: 40px;
  font-weight: 700;
  letter-spacing: -1px;
}

.header-3 {
  font-size: 24px;
  font-weight: bold;
  letter-spacing: -1px;
}

.subtitle {
  font-size: 24px;
  font-weight: 500;
}

.body {
  font-size: 16px;
  line-height: 140%;
}

.small {
  font-size: 14px;
  line-height: 140%;
}

.pretitle {
  font-size: 12px;
  letter-spacing: 3%;
  font-weight: bold;
  text-transform: uppercase;
}

.button {
  font-size: 16px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.04rem;
  text-transform: uppercase;
}

.link {
  font-size: 16px;
}
