.gradientText {
	background: linear-gradient(
		135deg,
		#ff00d2,
		#fed90f,
		#00a2ff,
		#000,
		#ff00d2,
		#fed90f,
		#dede,
		#09f1b8
	);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-size: 300% 300%;
	animation: gradient 8s ease-in-out infinite;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
