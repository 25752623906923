.searchBar {
  outline: none;
  border: none;
  width: 90%;
  font-weight: 500;
}

.searchBar::placeholder {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  color: var(--text-body);
  line-height: 140%;
}
