.fileInputArea {
	position: relative;
	transition: all 0.3s;
	width: fit-content;
	height: fit-content;
}
.fileInputArea:hover {
	box-shadow: 0 2px 20px 2px rgba(0, 0, 0, 0.179);
}
.fileInput {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	width: inherit;
	height: 40px;
	opacity: 0;
}
